const MDEX_LIST = 'https://raw.githubusercontent.com/mdexSwap/hswap/main/tokenlist.json'

export const UNSUPPORTED_LIST_URLS: string[] = []

// lower index == higher priority for token import
export const DEFAULT_LIST_OF_LISTS: string[] = [...UNSUPPORTED_LIST_URLS, MDEX_LIST]

// default lists to be 'active' aka searched across
export const DEFAULT_ACTIVE_LIST_URLS: string[] = []

/* TENSO DEMAIS */